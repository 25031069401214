import { compareAsc, format, lastDayOfMonth, isAfter, add } from 'date-fns';
import { t } from 'src/i18n/config';

const toTC = {
  Monday: t('monday'),
  Tuesday: t('tuesday'),
  Wednesday: t('wednesday'),
  Thursday: t('thursday'),
  Friday: t('friday'),
  Saturday: t('saturday'),
  Sunday: t('sunday'),
};

const periodToTC = {
  morning: t('morning'),
  afternoon: t('afternoon'),
  evening: t('evening'),
};

const periodToString = {
  morning: t('periodMorning'),
  afternoon: t('periodAfternoon'),
  evening: t('periodEvening'),
};

const periodToComparingValue = {
  上午: 0,
  下午: 1,
  晚間: 2,
};

export const toPeriod = (date) => {
  const hr = parseInt(date.toTimeString().substring(0, 2), 10);
  if (hr > 0 && hr < 14) {
    return 'morning';
  }
  if (hr >= 14 && hr < 18) {
    return 'afternoon';
  }
  return 'evening';
};

export const PeriodToTC = (period) => {
  return periodToTC[period];
};

export const PeriodToString = (period) => {
  return periodToString[period];
};

export const toDayOfWeek = (date) => {
  return toDayOfWeekNumberOnly(date);
};

export const toDayOfWeekNumberOnly = (date) => {
  const day = format(date, 'iiii');
  return toTC[day];
};

export const toYear = (date) => {
  return format(date, 'y');
};

export const toMonth = (date) => {
  return format(date, 'M');
};

export const toDay = (date) => {
  return format(date, 'd');
};

export const compare = (
  { date: xdate, time: xtime },
  { date: ydate, time: ytime }
) => {
  const datecomparing = compareAsc(xdate, ydate);
  if (datecomparing === 0) {
    return periodToComparingValue[xtime] - periodToComparingValue[ytime];
  }
  return datecomparing;
};

export const toDisplayString = (date) => {
  return format(date, 'yyyy-MM-dd');
};

export const toTime = (date) => {
  return format(date, 'HH:mm');
};

export const getAllYears = (showRepublicYear) => {
  const nowyear = new Date().getFullYear();
  const allyears = [];
  for (let i = nowyear; i > 1911; i--) {
    const displayYear = showRepublicYear
      ? `${i - 1911} ${t('year')}`
      : `${i} ${t('year')}`;
    allyears.push({
      id: `${i}`,
      name: displayYear,
      value: `${i}`,
    });
  }
  return allyears;
};

export const getMonth = () => {
  const allmonth = [];
  for (let i = 1; i < 13; i++) {
    allmonth.push({
      id: `${i}`,
      name: `${i} ${t('month')}`,
      value: `${i}`,
    });
  }
  return allmonth;
};

export const getDays = (d) => {
  const date = d || new Date();
  const lastday = lastDayOfMonth(date);
  const lastdaynumber = lastday.getDate();
  const alldays = [];
  for (let i = 1; i < lastdaynumber + 1; i++) {
    alldays.push({
      id: `${i}`,
      name: `${i} ${t('day')}`,
      value: `${i}`,
    });
  }
  return alldays;
};

export const isAfterMin = (date, min) => {
  return isAfter(
    new Date(),
    add(date, {
      minutes: min,
    })
  );
};

export const isValidDate = (d) => {
  return d instanceof Date && !isNaN(d);
};
